import axios from "axios";
// import { notify, objectToHTTPQuery } from "./helpers";
import { getUserToken, logOutUser, rememberRoute } from "./auth";

let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    token: localStorage.getItem("token"),
};

let fileHeaders = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    token: localStorage.getItem("token"),
};

export const baseContentURL = "https://api.kobokist.thehebrewsshelters.com/";
//export const baseContentURL = "http://10.211.55.4/";

const Endpoint = {
    init: () => {
        // accountId = process.env.REACT_APPg5b657_ACCOUNT_ID;
        let token = getUserToken();
        if (token) axios.defaults.headers.common["Authorization"] = token;
        axios.defaults.baseURL = "https://api.kobokist.thehebrewsshelters.com/api";
        //axios.defaults.baseURL = "http://10.211.55.4/api";

        // Intercept 401 HTTP Error code in API
        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (!error.response) {
                    //No response
                    // notify("Seems like you're offline, check internet connection")
                } else if (
                    error.response &&
                    error.response.status === 401 &&
                    error.response.config.url !== "/login-register"
                ) {
                    // rememberRoute();
                    logOutUser();
                }
                return Promise.reject(error.response);
            }
        );
    },

    signInWithGoogle: (data) => {
        return axios.post(`/Account/LoginWithGoogle`, data, headers);
    },
    signIn: (data) => {
        return axios.post(`/Account/Login`, data, headers);
    },
     createAccount: (data) => {
         return axios.post(`/Account/CreateAccount`, data, headers);
    },
    validateotp: (email, code) => {
        return axios.post(`/Account/ValidateOtp?email=${email}&code=${code}`, headers);
    },
    allServiceCategories: () => {
        return axios.get(`/ServiceCategory/GetAllServiceCategories`, headers);
    },
    allServiceCategoriesByLocation: (location) => {
        return axios.get(`/ServiceCategory/GetAvailableServicesByLocation?location=${location}`, headers);
    },
    getServicePlansByLocations: (location, categoryId) => {
        return axios.get(`/ServiceCategoryPricing/GetAssociatedPlansByLocation?location=${location}&serviceCategoryId=${categoryId}`, headers);
    },
    getUserProfileByUserId: (userId) => {
        return axios.get(`/Account/GetUserByUserId?userId=${userId}`, headers);
    },
    processCheckoutItems: (data) => {
        return axios.post(`/Payment/ServiceCheckout`,data, headers);
    },
    validateAndUpdatePayment: (data) => {
        return axios.post(`/Payment/ValidateTransaction?reference=${data}`, headers);
    },
    getServiceRequestList: (pageNumber, pageSize) => {
        return axios.get(`/ServiceRequest/GetServiceRequests?pageNumber=${pageNumber}&pageSize=${pageSize}`, headers);
    },
    getSubscriptionPlanList: (data) => {
        return axios.get(`/Subscription/GetAssociatedSubscriptionsByLocation?location=${data}`, headers);
    },
    subscriptionCheckout: (data) => {
        return axios.post(`/Subscription/ServiceCheckout`, data, headers);
    },
    getUserSubscription: (data) => {
        return axios.get(`/Subscription/UserSubscriptionDetails?userId=${data}`, headers);
    },
    requestServiceViaSubscription: (data) => {
        return axios.post(`/Subscription/RequestService`, data, headers);
    },
    requestKYCVerification: (data) => {
        return axios.post(`/Account/RequestKYCVerification`, data, headers);
    },
    updateProfile: (data) => {
        return axios.post(`/Account/UpdateProfile`, data, headers);
    },
    getFishShopList: (data) => {
        return axios.get(`/FishShop/GetFishShop`, data, headers);
    },
    fishShopCheckout: (data) => {
        return axios.post(`/FishShop/FishShopCheckout`, data, headers);
    },
    getUserFishShopSummary: (userId) => {
        return axios.get(`/FishShop/GetUserFishShopDetails?userId=${userId}`, headers);
    },
    getPropertyList: (data) => {
        return axios.get(`/KoboProperties/GetKoboProperties`, data, headers);
    },
};

export default Endpoint;
