
import UserLayout from "../../layouts/user";
import UserHeader from "../../layouts/user/header";
import withLayout from "../../layouts/withLayout";
import justclean from "../../assets/images/clean.jpg"
import { Button, Modal, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import mt2 from "../../assets/images/mt2.jpg"
import prop from "../../assets/images/prop.jpeg"
import { ToastContainer, toast } from 'react-toastify';
import { states } from "../../util/statestore";
import { FillSpinner } from "react-spinners-kit";
import $ from "jquery"
import { useEffect, useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { enquireScreen } from 'enquire-js';
import Endpoint, { baseContentURL } from "../../util/endpoint";
import { activeLocationCheck, addToCart, cacheServiceLocation, KOBO_SERVICE_LOCATION, SUBSCRIPTION_ROUTE_PROPS } from "../../util/helpers";
import { UserContext } from "../../context/userProvider";
import MeetingGroundHeader from "../../layouts/user/meeting-ground-header";
import RealEstateHeader from "../../layouts/user/real-estate-header";


// const meetingGroundImulation = [
//     {
//         name: "4 Bedroom Duplex",
//         date: "450,000",
//         location: "Enugu, Enugu State",
//         img: prop,
//         price: "450,000",
//         description: "This spacious 4-bedroom family home offers an open floor plan, modern amenities, and a large backyard perfect for entertaining. Located in a quiet neighborhood, it's close to top schools and shopping centers"
//     }
   
// ]


const KoboProperties = (props) => {
    const location = useLocation();
    const { routeProps } = location.state?.some || {};
    const { serviceitem, locationName } = location.state?.some || {};
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [incomingProps, setIncomingProps] = useState(props?.location?.state);
    const [pricingArr, setpricingArr] = useState([]);
    const [meetingGroundImulation, setmeetingGroundImulation] = useState([]);
    const userContext = useContext(UserContext);
    const [selectedStateValue, setselectedStateValue] = useState('')
    const [selectedStateName, setselectedStateName] = useState('')
    const [selectedLgaValue, setselectedLgaValue] = useState('')
    const [selectedLgaName, setselectedLgaName] = useState('')
    const [lgaArr, setLgaArr] = useState([])
    const [serviceCategoryArr, setserviceCategoryArr] = useState([])
    const [fetching, setfetching] = useState(true)
    const [selectedPlan, setselectedPlan] = useState({})
    const [serviceDate, setserviceDate] = useState("")
    const [serviceTime, setserviceTime] = useState("")
    const [cachedLocation, setcachedLocation] = useState(JSON.parse(localStorage.getItem(KOBO_SERVICE_LOCATION)));

    const [spinning, setSpinning] = useState(false);
    const [percent, setPercent] = useState(0);

    const showLoader = () => {
        setSpinning(true);
    };

    const handleRequestServiceAsync = () => {

        if (serviceDate == null || serviceDate == "" || serviceDate == null || serviceDate == "") {
            toast.error(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>
                Select prefered date/time for service before continuing
            </p>)
            return;
        }
        setSpinning(true);
        setIsModalOpen(false);
        const payload = {
            "serviceDate": serviceDate,
            "serviceTime": serviceTime,
            "userId": userContext?.userPayload?.userId,
            "serviceCategoryId": serviceitem?.id
        }
        Endpoint.requestServiceViaSubscription(payload)
            .then((res) => {
                console.log(res?.data, "reqqq")
                setSpinning(false);
                toast.success(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>
                    Your service request was successful
                </p>)
                setTimeout(() => {
                    window.location.href = "/userindex"
                }, 5000);
            })
            .catch((err) => {
                setSpinning(false);
                console.log(err)
            })
    }


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleSelectPlan = (data) => {
        var filter = pricingArr.filter((x) => x.id == data);
        console.log(filter)
        setselectedPlan(filter[0])
    }
    const handleAddToCart = (item) => {
        userContext.globalAddToCart(selectedPlan, serviceDate, serviceTime)
        setIsModalOpen(false)
        // toast.success(<p className="outfit-500 text-white" style={{fontSize:"15px"}}>Added to Cart</p>);
        if (cachedLocation != null && cachedLocation?.lga != null) {

        }
        else {
            cacheServiceLocation(selectedStateName, selectedLgaName);
        }

    }

    const fetchPropertyList = (data) => {
        setpricingArr([])

        Endpoint.getPropertyList(data || cachedLocation?.lga, serviceitem?.id)
            .then((res) => {
                console.log(res?.data)
                setmeetingGroundImulation(res?.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleServiceDate = (e) => {
        console.log(e.target.value)
        setserviceDate(e.target.value)
    }
    const handleServiceTime = (e) => {
        console.log(e.target.value)
        setserviceTime(e.target.value)
    }
    useEffect(() => {
        // console.log(routeProps, "routeProps")
        // console.log(serviceitem, "serviceitem")
        fetchPropertyList()
        window.scrollTo(0, 0);
        setTimeout(() => {
            $("#preloader").delay(450).fadeOut("slow")
        }, 1000);

        enquireScreen((b) => {
            setIsMobile(b)
        });
    }, [cachedLocation]);

    return (
        <div className="">
            <RealEstateHeader />
            <Spin
                spinning={spinning}
                indicator={<LoadingOutlined spin />} size="large"
                className="text-white"
                fullscreen />
            <div id="preloader">
                <div id="status">
                    <FillSpinner color="#ff5f00" backColor="#FFF" frontColor="#FFF" size={20} />
                    <p className='loader-text mt-1' style={{ fontWeight: "600" }}><span className='' style={{ color: "#076fcc" }}>Kobo<span style={{ color: "#ff5f00" }}>Kist</span></span></p>
                </div>
            </div>
            <ToastContainer
                position="top-center"
                theme="colored"
            />


            <div>
                <div className="page-title-section section">
                    <div className="page-title">
                        <div className="container">
                            <h1 className="title" style={{ fontWeight: "600" }}> {routeProps ? routeProps : "Properties"}</h1>
                        </div>
                    </div>
                    <div className="page-breadcrumb mt-3">
                        <div className="container">
                            <ul className="breadcrumb outfit-400">
                                <li><a href="#">Home / </a></li>
                                <li className="current"> Properties</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="section section-padding-bottom">
                    <div className="container">
                        <div className="row justify-content-between align-items-center max-mb-20">
                            <div className="col-sm-auto col-12 max-mb-10">
                                {/* <p className="result-count">We found <span>14</span> events available for you</p> */}
                            </div>
                            <div className="col-md-3 col-12">
                                <select style={{ border: "1px solid #e6e6e6a3", height: "40px", background: "#80808012" }} className="sort-by form-control">
                                    <option selected="selected">Default</option>
                                    <option value="popularity">Popularity</option>
                                    <option value="date">Latest</option>
                                    <option value="price">Price: low to high</option>
                                    <option value="price-desc">Price: high to low</option>
                                </select>
                            </div>
                        </div>
                        <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">
                            {meetingGroundImulation && meetingGroundImulation.map((x, i) => {
                                return (
                                    <div className="col max-mb-30" data-aos="fade-up">
                                        <div className="event">
                                            <div className="thumbnail" style={{height:"270px"}}>
                                                <a href="#" className="image"><img src={baseContentURL + x?.imageUrl1} alt="Event Image" /></a>
                                                <div className="event-overlay-background" />
                                                <div className="event-overlay-content">
                                                    <Link
                                                        state={{
                                                            some: {
                                                                routeProps: "",
                                                                serviceitem: x,
                                                                // locationName: selectedLgaName
                                                            }
                                                        }}
                                                        className="btn btn-md btn-light btn-hover-light theme-color"
                                                        to="/property-details">View</Link>
                                                </div>
                                            </div>
                                            <div className="info">
                                                <span className="date">{x?.date}</span>
                                                <h3 className="title"><Link href="/property-details">{x?.name}</Link></h3>
                                                <ul className="event-location">
                                                    <li><i className="fa fa-map-marker-alt" />{x?.location}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}




                        </div>
                        <div className="row max-mt-50">
                            <div className="col-12">
                                <ul className="pagination center d-flex">
                                    <li><a href="#" className="prev">PREV</a></li>
                                    <li><a href="#" className="active">1</a></li>
                                    <li><a href="#">2</a></li>
                                    <li><a href="#" className="next">NEXT</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default KoboProperties;